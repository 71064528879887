.navigation-main {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  /* align-items: flex-end; */
  justify-content: space-between;
  min-height: 3rem;
}

.navigation-main-group {
  display: flex;
  align-items: flex-end;
  margin-top: 2.5rem;
}

.app-new-button {
  margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .navigation-main {
    margin-top: 0rem;
  }

  .navigation-main-group {
    margin-top: 1.5rem;
  }
}
